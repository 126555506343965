function App() {
  return (
    <div className="App">
      <h1>Hello World</h1>
      <p>
        kali ini saya akan belajar react js <br />
        dikarenakan saya ga tau mau buat project apaan di back end wkwkwk
      </p>
    </div>
 );
}

 class header {
    constructor(title) {
      this.title = title;
    }
 }
 

export default App;
